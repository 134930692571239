import { Box } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Section from '../Section';
import { Post } from './Post';

export type Post = {
  title: { richText: { type: string; text: string }[] };
  description: { richText: { type: string; text: string }[] };
  url: string;
  image: { gatsbyImageData: IGatsbyImageData; alt: string };
  tags: string[];
};

export type PostsProps = {
  lang: string;
  filteredPosts: Post[];
  list: Post[];
  hasMore: boolean;
};

export const Posts = React.forwardRef(
  (
    { lang, filteredPosts, list, hasMore }: PostsProps,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => (
    <Section
      sx={{
        px: { xs: 3, sm: 4 },
        py: { xs: 4, sm: 6, md: 8 },
        display: `flex`,
        flexDirection: `column`,
      }}
      id="posts"
    >
      <Box
        sx={{
          display: `grid`,
          maxWidth: `xl`,
          width: 1,
          mx: `auto`,
          gridTemplateColumns: { lg: `repeat(3, 1fr)` },
          columnGap: 3,
          rowGap: { xs: 6, lg: 16 },
          justifyContent: `center`,
        }}
        id="posts-panel"
      >
        {filteredPosts &&
          filteredPosts.length > 0 &&
          list.map((post, i) => (
            <Post key={uuidv4()} post={post} i={i} lang={lang} />
          ))}
      </Box>
      <Box sx={{ textAlign: `center`, mt: { xs: 6, lg: 16 } }} ref={ref}>
        {hasMore ? <p>Loading...</p> : <p>No more results</p>}
      </Box>
    </Section>
  ),
);
