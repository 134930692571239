import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useEffect, useRef, useState } from 'react';

type Post = {
  title: { richText: { type: string; text: string }[] };
  description: { richText: { type: string; text: string }[] };
  url: string;
  image: { gatsbyImageData: IGatsbyImageData; alt: string };
  tags: string[];
};

const useFilteredAndPaginatedPosts = (posts: Post[]): any => {
  const postList = posts ?? [];

  // list tags
  const filteredTags = [
    `Consumer Insights`,
    `Conversion Optimization`,
    `Customer Retention`,
    `Data Science`,
  ];

  const dedupedTags = [
    ...new Set(postList.map((post: Post) => post.tags).flat()),
  ];

  const tags = filteredTags.filter((tag) => dedupedTags.includes(tag));

  // selected tag state
  const [selectedTag, setSelectedTag] = useState<null | string>(null);

  // filter posts by category
  const filteredPosts = postList.filter((post: Post) => {
    if (selectedTag === null) {
      return post;
    }
    if (post.tags.includes(selectedTag)) {
      return post;
    }
    return null;
  });

  // State for the list of showing posts
  const [list, setList] = useState([...postList.slice(0, 9)]);
  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(filteredPosts.length > 9);
  // Set a ref for the loading div
  const loadRef = useRef();
  // Handle intersection with load more div
  const handleObserver = (entities: any) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  // when the selectedTag changes, reset the list
  useEffect(() => {
    setList([...filteredPosts.slice(0, 9)]);
  }, [selectedTag]);

  // Initialize the intersection observer API
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: `20px`,
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);

  // Handle loading more posts
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < filteredPosts.length;
      const nextResults = isMore
        ? filteredPosts.slice(currentLength, currentLength + 10)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < filteredPosts.length;
    setHasMore(isMore);
  }, [list]);

  return {
    list,
    loadRef,
    tags,
    selectedTag,
    setSelectedTag,
    filteredPosts,
    hasMore,
  };
};

export default useFilteredAndPaginatedPosts;
